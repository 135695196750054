<template>
  <div>
    <h1>Hier können Sie Ihren Premium Account von Bookingflix ganz flix verlängern</h1>
    <h3>
    Bitte beachten Sie dieses Schreiben aufmerksam.
    Es geht um Ihre Terminbuchungen!
    </h3>
    <p>Wenn möglich, bitte direkt den Premium-Account für ein Jahr verlängern. Das spart Zeit für alle Seiten, da Sie nur eine Rechnung im Jahr erhalten. Sie können dennoch monatlich kündigen. Zuviel gezahlte Beiträge bekommen Sie selbstverständlich im Falle einer vorzeitigen Kündigung sofort zurück. Wir sind flix, wir sind fair :-).</p>
    <p>
    Sie können aber auch den Free-Account nutzen. Dieser wird in den kommenden Tagen automatisch aktiv. Ihr Premium-Account wird abgeschaltet. Es können dann allerdings nur noch insgesamt 60 Termine je Monat gebucht werden von Ihren Kunden. Je Account ist nur ein Buchungs-Kalender nutzbar (zu viel angelegte Kalender werden deaktiviert. Bitte Termin-Daten ggf. sichern). Und der Buchungszeitraum für Ihre Kunden ist begrenzt.
    </p>
    <p>
    Die Verlängerung des Premium-Accounts erfolgt unkompliziert per Lastschriftverfahren. Nachfolgend die Zahlungspläne. Alternativ geht es auch per Kauf auf Rechnung (siehe unten). Sie erhalten in jedem Fall eine Rechnung per Post. Bei Fragen oder Problemen dazu: <a href="mailto:support@bookingflix.com" class="flix-html-a">support@bookingflix.com</a> oder <a href="tel:023024007736" class="flix-html-a">02302-4007736</a>
    </p>
    <plans />
  </div>
</template>
<script>
export default {
  components: {
    plans () { return import('./plans') }
  },
  props: {},
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
