import { render, staticRenderFns } from "./paymentInfos.vue?vue&type=template&id=179bf376&scoped=true"
import script from "./paymentInfos.vue?vue&type=script&lang=js"
export * from "./paymentInfos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "179bf376",
  null
  
)

export default component.exports